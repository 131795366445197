/*
 * Copyright Thoughtworks, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import Stream from "mithril/stream";
import {ValidatableMixin} from "models/mixins/new_validatable_mixin";

/** to validate names of pipelines/groups/templates */
export class ModelWithNameIdentifierValidator extends ValidatableMixin {
  name: Stream<string> = Stream();

  constructor() {
    super();
    this.validatePresenceOf("name");
    this.validateIdFormat("name");
  }
}
