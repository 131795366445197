// This file is automatically generated by `generated_js` task. Any changes will be lost
/* tslint:disable */
export const GoCDVersion = {
  "version": "25.1.0",
  "buildNumber": "20129",
  "gitSha": "5ca0c3de227fdbc4bf38480095d9385bbbc14b13",
  "fullVersion": "25.1.0-20129",
  "formattedVersion": "25.1.0 (20129-5ca0c3de227fdbc4bf38480095d9385bbbc14b13)"
}


function stripLeadingPrefix(suffix: string, prefix: string) {
  if (suffix.startsWith(prefix)) {
      suffix = suffix.substring(1);
  }
  return suffix;
}

export function docsUrl(suffix: string = '') {
  return `https://docs.gocd.org/25.1.0/${stripLeadingPrefix(suffix, '/')}`
}

export function apiDocsUrl(suffix: string = '') {
  return `https://api.gocd.org/25.1.0/${stripLeadingPrefix(suffix, '#')}`
}
    
